import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAPI, updateAWSUserAPI, djangoQueryUserAPI, djangoOnboardingClientAPI } from 'APIs/user';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';

const initialState = {
    isLoggedIn: false,
    isAdmin: false,
    isInitialized: false,
    isLoading: false,
    onboardComplete: false,
    isSuperUser: false,
    user: {},
    org: {},
    client: {},
    onboardingStatus: {},
    userData: {},
    onboardingPageIsInitialized: false,
    qboAuthState: {
        // authenticated: true,
        // retryAuthentication: false,
        accessTokenSaved: false,
        qboRedirectPerformed: false,
        retrySendReport: false
    }
};

// ==============================|| REDUX STORE: ACCOUNT DATA ||============================== //

const GET_USER = createAsyncThunk('account/user/get', async () => {
    // debugger; // eslint-disable-line no-debugger
    console.log('getUser');
    const response = await getUserAPI();
    console.log('getUser response', response);
    return response;
});

const UPDATE_USER = createAsyncThunk('account/user/update', async ({ data = {}, file }) => {
    // First Upload photo if necessary
    if (file) {
        const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type });
        data.photoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update User data
    console.log('update user');
    const response = await updateAWSUserAPI({ data });
    console.log('updateUser response', response);
    return response;
});

const GET_USER_DATA = createAsyncThunk('users/', async ({ email, userId = null }) => {
    const response = await djangoQueryUserAPI({ email, userId });
    console.log('GET_USER_DATA>>>', response);
    return response;
});

const setLocalSuperuser = (data) => ({
    type: 'SET_LOCAL_SUPERUSER',
    payload: data
});

const updateLocalUser = (data) => ({
    type: 'UPDATE_LOCAL_USER',
    payload: data
});

const updateQBOAuthState = (data) => ({
    type: 'UPDATE_QBO_AUTH_STATE',
    payload: data
});

const setOnboardingPageInitialized = (data) => ({
    type: 'SET_ONBOARDING_PAGE_INITIALIZED',
    payload: data
});

const ONBOARD_USER_DATA = createAsyncThunk('account/user/onboard', async ({ data = {} }) => {
    const response = await djangoOnboardingClientAPI({ data });
    return response;
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        LOGIN(state, action) {
            // debugger; // eslint-disable-line no-debugger
            // state = { ...initialState };
            // console.log('action.payload', action.payload);
            state.isLoggedIn = true;
            state.onboardComplete = false;
            state.onboardingPageIsInitialized = false;
            state.user = { ...state.user, ...action.payload };
            state.qboAuthState = {
                accessTokenSaved: false,
                qboRedirectPerformed: false,
                retrySendReport: false
            };
            // state.isSuperUser = state.user.email && state.user.email === 'max@getmyfixe.com';
            state.isSuperUser = state.user.type === 'admin';
            state.isInitialized = true;
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
            state.isInitialized = false;
            state.onboardComplete = false;
            state.client = {};
            state.isSuperUser = false;
            state.userData = {};
            state.onboardingPageIsInitialized = false;
            state.qboAuthState = {};
            const keepKeys = ['givenName', 'familyName', 'email'];
            Object.keys(state.user).forEach((key) => keepKeys.includes(key) || delete state.user[key]);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_USER.fulfilled, (state, action) => {
                state.isInitialized = true;
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(UPDATE_USER.fulfilled, (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(GET_USER_DATA.fulfilled, (state, action) => {
                const userData = Array.isArray(action.payload) ? action.payload?.[0] : action.payload;
                state.userData = userData;
                state.onboardComplete = !!userData?.client?.onboarding_status?.completed;
                state.isInitialized = true;
            })
            .addCase('SET_LOCAL_SUPERUSER', (state, action) => {
                state.isSuperUser = action.payload;
            })
            .addCase('UPDATE_LOCAL_USER', (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase('SET_ONBOARDING_PAGE_INITIALIZED', (state, action) => {
                state.onboardingPageIsInitialized = action.payload;
            })
            .addCase('UPDATE_QBO_AUTH_STATE', (state, action) => {
                state.qboAuthState = { ...state.qboAuthState, ...action.payload };
            })
            .addCase(ONBOARD_USER_DATA.fulfilled, (state, action) => {
                state.userData = { ...state.userData, ...action.payload };
                // state.client = action.payload.client;
                // state.location = action.payload.location ? { ...state.location, ...action.payload.location} : state.location;
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                }
            );
    }
});

const isLoading = (state) => state?.account?.isLoading;
const loginStatus = (state) => state?.account?.isLoggedIn;
const userData = (state) => state?.account?.user;
const userAPIData = (state) => state?.account?.userData;
const userClientAPIData = (state) => state?.account?.userData?.client;
const clientOnboardingStatus = (state) => state?.account?.userData?.client?.onboarding_status;
// const onboardComplete = (state) => state?.account?.onboardComplete;
const onboardComplete = (state) => state?.account?.userData?.client?.onboarding_status?.completed;
const isInitialized = (state) => state?.account?.isInitialized;
const qboAuthState = (state) => state?.account?.qboAuthState;
const { LOGIN, LOGOUT } = accountSlice.actions;

export {
    LOGIN,
    LOGOUT,
    GET_USER,
    UPDATE_USER,
    GET_USER_DATA,
    ONBOARD_USER_DATA,
    loginStatus,
    userData,
    isLoading,
    setLocalSuperuser,
    setOnboardingPageInitialized,
    onboardComplete,
    userAPIData,
    userClientAPIData,
    clientOnboardingStatus,
    updateLocalUser,
    isInitialized,
    updateQBOAuthState,
    qboAuthState
};
export default accountSlice.reducer;