/* eslint-disable */

import { apiGet, awsApiUpdate, apiPost, apiDjangoGet, apiPostDjango } from './apiClient';

const getUserAPI = async () => apiGet({ url: `/user` });

// const djangoGetUserAPI = async ({ id }) => apiDjangoGet({ url: `/users/${id}` });

const djangoQueryUserAPI = async ({ email, userId = null }) => {
    const userIdParam = userId ? `&user_id=${userId}` : '';
    // const url = `/users/?email=${encodeURIComponent(email)}`;
    const url = `/users/?email=${encodeURIComponent(email)}${userIdParam}`;
    return apiDjangoGet({ url });
};

const djangoOnboardingClientAPI = async ({ data }) => apiPostDjango({ url: `/clients/onboarding`, data });

const updateAWSUserAPI = async ({ data }) => awsApiUpdate({ url: `/user`, data });

const getUserListAPI = async () => apiGet({ url: `/user/list` });

const adminUpdateUserAPI = async ({ id, data }) => awsApiUpdate({ url: `/admin/user?id=${id}`, data });

const createUserAPI = async ({ data }) => apiPost({ url: `/user`, data });

const lockUserAPI = async ({ id }) => awsApiUpdate({ url: `/user/lock?id=${id}` });

export {
    getUserAPI,
    updateAWSUserAPI,
    getUserListAPI,
    adminUpdateUserAPI,
    createUserAPI,
    lockUserAPI,
    djangoOnboardingClientAPI,
    djangoQueryUserAPI
};
