import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';

// page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const SamplePage2 = Loadable(lazy(() => import('views/sample-page2')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const AdminDashboard = Loadable(lazy(() => import('views/dashboard/AdminDashboard')));
const UsersPage = Loadable(lazy(() => import('views/users')));
const UserDetail = Loadable(lazy(() => import('views/users/UserDetail')));
// const ClientListPage = Loadable(lazy(() => import('views/clients/ClientList')));
const ClientsPage = Loadable(lazy(() => import('views/clients')));
const ClientDetail = Loadable(lazy(() => import('views/clients/ClientDetail')));
const ComingSoon = Loadable(lazy(() => import('views/dashboard/ComingSoon')));
const NotFoundPage = Loadable(lazy(() => import('views/common/404')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: '/',
            element: <AdminDashboard />
        },
        {
            path: '/page1',
            element: <SamplePage />
        },
        {
            path: '/page2',
            element: <SamplePage2 />
        },
        {
            path: '/admin/users',
            element: <UsersPage />
        },
        {
            path: '/admin/user',
            element: <UserDetail />,
            children: [{ path: ':id', element: <UserDetail /> }]
        },
        {
            path: '/admin/clients',
            element: <ClientsPage />
        },
        {
            path: 'admin/settings',
            element: <UserSettings />
        },
        {
            path: '/admin/messages',
            element: <ComingSoon props={{ pageName: 'Admin Messages Page' }} />
        },
        {
            path: '/admin/reports',
            element: <ComingSoon props={{ pageName: 'Admin Reports Page' }} />
        },
        {
            path: '/admin/payroll',
            element: <ComingSoon props={{ pageName: 'Admin Payroll Page' }} />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;
