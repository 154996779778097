import { apiGet, awsApiUpdate } from './apiClient';

// Message Requests
const getMessageListAPI = async () => apiGet({ url: `/message/list` });

const getMessageAPI = async ({ messageID }) => apiGet({ url: `/message?id=${messageID}` });

const updateMessageAPI = async ({ id, data }) => awsApiUpdate({ url: `/message?id=${id}`, data });

const createMessageAPI = async ({ data }) => undefined;
// const url = `${DOMAIN}message`;
// const response = await apiPost({ method: 'POST', url, body });
//     return undefined;
// };

const deleteMessageAPI = async ({ id }) => undefined;
// const url = `${DOMAIN}message?id=${id}`;
// const response = await apiFetch({ method: 'DELETE', url });
//     return undefined;
// };

export { getMessageListAPI, getMessageAPI, updateMessageAPI, createMessageAPI, deleteMessageAPI };
