// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconHelp, IconSitemap, IconUsers, IconCoin, IconMessages, IconChartBar, IconCash } from '@tabler/icons';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin-menu',
    title: <FormattedMessage id="admin-dashboard-title" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'menu-messages',
            title: <FormattedMessage id="menu-messages" />,
            type: 'item',
            url: '/admin/messages',
            icon: IconMessages,
            breadcrumbs: false
        },
        {
            id: 'clients-page',
            title: 'Clients',
            type: 'item',
            url: '/admin/clients',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'menu-reports',
            title: <FormattedMessage id="menu-reports" />,
            type: 'item',
            url: '/admin/reports',
            icon: IconChartBar,
            breadcrumbs: false
        },
        {
            id: 'menu-payroll',
            title: <FormattedMessage id="menu-payroll" />,
            type: 'item',
            url: '/admin/payroll',
            icon: IconCash,
            breadcrumbs: false
        }
        // {
        //     id: 'users-page',
        //     title: <FormattedMessage id="users-page" />,
        //     type: 'item',
        //     url: '/users',
        //     icon: IconUsers,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'sample-page2',
        //     title: 'Sample Page 1',
        //     type: 'item',
        //     url: '/page2',
        //     icon: IconSitemap,
        //     breadcrumbs: false
        // }
    ]
};

export default admin;
