// Assets
import 'assets/scss/style.scss';
import { useTranslation } from 'react-i18next';

// Project Imports
import MainCard from 'ui-component/cards/MainCard';

const UninvitedUserWelcomeComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <h1>{t('generalCopy.thankYou')}</h1>
            <MainCard>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <p>{t('generalCopy.limitedAccess')}</p>

                    <p>{t('generalCopy.contactUs')}</p>

                    <ul>
                        <li>
                            {t('email')}: <a href="mailto: support@getmyfixe.com">support@getmyfixe.com</a>
                        </li>
                        <li>
                            {t('generalCopy.contactForm')}:{' '}
                            <a href="https://www.getmyfixe.com/contact-us/">{t('generalCopy.visitWebsite')}</a>
                        </li>
                    </ul>
                </div>
            </MainCard>
            <p style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {t('generalCopy.lookForward')}
            </p>
        </>
    );
};

const UninvitedUserWelcomePage = () => (
    <MainCard>
        <UninvitedUserWelcomeComponent />
    </MainCard>
);

export default UninvitedUserWelcomePage;
