/* eslint-disable */
// third-party
import { configureStore } from '@reduxjs/toolkit';
import { loadState } from './persistState';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountReducer from './slices/accountSlice';
import taskReducer from './slices/taskSlice';
import menuReducer from './slices/menu';
import statusReducer from './slices/statusSlice';
import userReducer from './slices/userSlice';
import clientReducer from './slices/clientSlice';
import messageReducer from './slices/messageSlice';
import reportReducer from './slices/reportSlice';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
    key: 'root',
    version: 1,
    storage
};

const rootReducer = persistReducer(persistConfig, userReducer);

const persistedState = loadState();

const store = configureStore({
    reducer: {
        menu: menuReducer,
        status: statusReducer, // showing alerts, error messages, etc
        account: accountReducer, // "business" account (billing stuff, etc)
        task: taskReducer, // for AM + client tasks they need to complete
        user: rootReducer, // for admins to update users on team
        client: clientReducer, // for clients managed by AMs
        message: messageReducer,
        report: reportReducer
    },
    preloadedState: persistedState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});
export const persistor = persistStore(store);
export default store;