import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ClientGuard from 'utils/route-guard/ClientGuard';
import { Message } from '@mui/icons-material';

// page routing
const ClientDashboard = Loadable(lazy(() => import('views/dashboard/ClientDashboard')));
const MessagePage = Loadable(lazy(() => import('views/messages')));
const PayrollPage = Loadable(lazy(() => import('views/payroll')));
const TeamPage = Loadable(lazy(() => import('views/team')));
const AccountingPage = Loadable(lazy(() => import('views/accounting')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PlayArea = Loadable(lazy(() => import('views/sample-page2')));
const ComingSoon = Loadable(lazy(() => import('views/dashboard/ComingSoon')));
const ClientReports = Loadable(lazy(() => import('views/reporting/ClientReports')));
const ReportsDetailPage = Loadable(lazy(() => import('views/reporting/ReportDetail')));
const NotFoundPage = Loadable(lazy(() => import('views/common/404')));

const MainRoutes = {
    path: '/client',
    element: (
        <ClientGuard>
            <MainLayout />
        </ClientGuard>
    ),
    children: [
        {
            path: '/client',
            element: <ClientDashboard />
        },
        {
            path: '/client/accounting',
            element: <AccountingPage />
        },
        {
            path: '/client/messages',
            element: <MessagePage props={{ pageName: 'Messages Page' }} />
        },
        {
            path: '/client/play',
            element: <PlayArea />
        },
        {
            path: '/client/settings',
            element: <UserSettings />
        },
        {
            path: '/client/reports',
            element: <ClientReports />,
            children: [{ path: ':id', element: <ClientReports /> }]
        },
        {
            path: '/client/report',
            element: <ReportsDetailPage />,
            children: [{ path: ':id', element: <ReportsDetailPage /> }]
        },
        {
            path: '/client/payroll',
            element: <PayrollPage props={{ pageName: 'Payroll Page' }} />
        },
        {
            path: '/client/team',
            element: <TeamPage props={{ pageName: 'Team Page' }} />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;