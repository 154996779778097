import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { CLIENT_DASHBOARD_PATH, ACCOUNT_MANAGER_DASHBOARD_PATH, ONBOARDING_HOME_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AdminGuard = ({ children }) => {
    const { isLoggedIn, userType, onboardComplete } = useAuth();
    const navigate = useNavigate();
    console.log('AuthGuard Admin: userType', userType);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        } else if (userType === 'am') {
            navigate(ACCOUNT_MANAGER_DASHBOARD_PATH, { replace: true });
        } else if (userType === 'client') {
            if (onboardComplete) {
                navigate(CLIENT_DASHBOARD_PATH, { replace: true });
            } else {
                navigate(ONBOARDING_HOME_PATH, { replace: true });
            }
        }
    }, [isLoggedIn, userType, onboardComplete, navigate]);

    return children;
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;
