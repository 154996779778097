/* eslint-disable */

import { apiPostDjango, apiDjangoGet, apiDjangoDelete, djangoApiClient } from './apiClient';
import { API_SERVER_URL } from 'config';
import appLogger from '../utils/common/appLogger.ts';

const CREATE_REPORT_URL = '/create-report/';
const CREATE_GL_REPORT_URL = '/create-report/';

const createReport = async ({ data }) => {
    try {
        const response = await apiPostDjango({ url: CREATE_REPORT_URL, data });

        console.log('>>>>createReport response', response);

        if (response.status == 401 && response?.data?.redirectUrl) {
            console.log('>>>>createReport unauthenticated. Entering oauth reauth flow...');
            const redirectUrl = `${process.env.REACT_APP_API_SERVER_URL}${response.data.redirectUrl}`;
            console.log('>>>>createReport redirecting to', redirectUrl);
            window.location.href = redirectUrl;
            return null;
        }

        // Check if the response is a redirect (status code in the 3xx range)
        if (response.status == 300 && response.status < 400) {
            // Redirect the user to the new URL returned by the Django API
            const newUrl = response.headers.get('Location');
            window.location.href = newUrl;
            return null;
        }
        return response;
    } catch (error) {
        // Handle other errors, e.g., network errors
        console.error('>>>>>>>>createReport ERROR:', error);
        return null;
    }
};

const checkAccessToken = async () => {
    try {
        const response = await apiDjangoGet({ url: '/access-token/' }); // Modify the URL according to your Django API
        const accessToken = response.data.access_token;
        if (accessToken) {
            // Access token is available, stop polling and redirect to the desired page with the access token
            window.location.href = `http://localhost:3000/am/reports?accessToken=${accessToken}`;
        } else {
            // Access token is not yet available, continue polling after a delay
            setTimeout(checkAccessToken, 1000); // Poll every 1 second (adjust the delay as needed)
        }
    } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
    }
};

const connectQBO = async ({ locationId }) => {
    const NAME = 'connectQBO()';
    try {
        const response = await apiDjangoGet({ url: `/locations/${locationId}/qbo-auth-url` });
        console.log('>>>>connectQBO response', response);
        const redirectUrl = response?.data?.authUrl;

        if (redirectUrl != null) {
            // Redirect the user to the auth URL
            window.location.href = redirectUrl;
        }
        return null;
    } catch (error) {
        console.error(NAME, error);
        return null;
    }
};

const initiateQBOAuth = async () => {
    try {
        const response = await apiDjangoGet({ url: '/auth/' });
        const redirectUrl = response.redirectUrl;
        // Redirect the user to the auth URL
        // window.location.href = redirectUrl;
        window.location.assign(redirectUrl);

        // Start the polling mechanism after the redirect
        checkAccessToken();
    } catch (error) {
        // Handle error if needed
        console.error('Error:', error);
    }
};

const getReportListAPI = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/reports', params });
    return response;
};

const deleteReportAPI = async ({ id }) => {
    const response = await apiDjangoDelete({ url: `/reports/${id}/` });
    return response;
};

const getReportData = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/report', params });
    return response?.data;
};

const getReportDataPreview = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/report-data', params });
    return response?.data;
};

const saveAccessToken = async ({ params }) => {
    const response = await apiDjangoGet({ url: '/callback', params });
    const responseOk = response?.status >= 200 && response?.status < 300;
    return responseOk;
};

const saveAccountMappingAPI = async ({ locationId, data }) => {
    const response = await apiPostDjango({ url: `/locations/${locationId}/save-account-mapping/`, data });
    return response;
};

export {
    createReport,
    initiateQBOAuth,
    getReportData,
    getReportListAPI,
    deleteReportAPI,
    saveAccessToken,
    getReportDataPreview,
    saveAccountMappingAPI,
    connectQBO
};
