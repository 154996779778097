import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Import API functions related to clients and files
import {
    getClientListAPI,
    updateClientAPI,
    createClientAPI,
    deleteClientAPI,
    onboardClientAPI,
    updateClientDjangoAPI,
    getDjangoClientListAPI
} from 'APIs/client';
import { getFileURLAPI, uploadFileWithURLAPI, adminGetFileURLAPI } from 'APIs/file';

// ==============================|| REDUX STORE: ATTORNEY DATA ||============================== //

// const GET_CLIENT_LIST = createAsyncThunk('client/list', async () => {
//     const response = await getClientListAPI();
//     return response;
// });

const GET_DJANGO_CLIENT_LIST = createAsyncThunk('clients/list', async () => {
    const response = await getDjangoClientListAPI();
    return response;
});

const createClient = createAsyncThunk('client/create', async ({ data }) => {
    const response = await createClientAPI({ data });
    return response;
});

const ONBOARD_CLIENT = createAsyncThunk('clients/onboard', async ({ data }) => {
    console.log(data);
    const response = await onboardClientAPI({ data });
    return response;
});

const UPDATE_CLIENT_DJANGO = createAsyncThunk('clients/update-django', async ({ id, data }) => {
    const response = await updateClientDjangoAPI({ id, data });
    return response;
});

const UPDATE_CLIENT = createAsyncThunk('client/update', async ({ id, data = {}, file }) => {
    if (!id) {
        throw new Error('Missing clientID');
    }

    // First Upload Logo if necessary
    if (file) {
        const uploadURL = await adminGetFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type, id });
        data.logoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update User data
    const response = await updateClientAPI({ id, data });
    return response;
});

const DELETE_CLIENT = createAsyncThunk('client/delete', async ({ id }) => {
    const response = await deleteClientAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    clients: [],
    error: null
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        create(state, action) {
            state.clients.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.clients = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createClient.fulfilled, (state, action) => {
                state.clients.push(action.payload);
            })
            .addCase(UPDATE_CLIENT.fulfilled, (state, action) => {
                state.clients = state.clients.map((client) => {
                    if (client.id === action.payload.id) {
                        return { ...client, ...action.payload };
                    }
                    return client;
                });
            })
            .addCase(DELETE_CLIENT.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.clients = state.clients.filter((item) => item.id !== deletedID);
                }
            })
            // .addCase(GET_CLIENT_LIST.fulfilled, (state, action) => {
            //     state.clients = [...action.payload];
            //     state.initialized = true;
            // })
            .addCase(GET_DJANGO_CLIENT_LIST.fulfilled, (state, action) => {
                state.clients = [...action.payload];
                state.initialized = true;
            })
            .addCase(ONBOARD_CLIENT.fulfilled, (state, action) => {
                // Uncomment the following two lines for cleaning up state while debugging
                // state.clients = state.clients.filter((item) => item !== null);
                // state.clients = state.clients.filter((item) => 'id' in item);
                if (action.payload?.client) {
                    state.clients.push(action.payload.client);
                }
            })
            .addCase(ONBOARD_CLIENT.rejected, (state, action) => {
                // Added for cleaning up state while debugging
                state.error = action.payload;
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                }
            );
    }
});

const { create, logout } = clientSlice.actions;
const initialized = (state) => state?.client?.initialized;
const clients = (state) => state?.client?.clients;

export {
    // GET_CLIENT_LIST,
    UPDATE_CLIENT,
    createClient,
    UPDATE_CLIENT_DJANGO,
    DELETE_CLIENT,
    initialized,
    clients,
    create,
    logout,
    ONBOARD_CLIENT,
    GET_DJANGO_CLIENT_LIST
};
export default clientSlice.reducer;