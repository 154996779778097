import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AMGuard from 'utils/route-guard/AMGuard';

// page routing
const DashboardAM = Loadable(lazy(() => import('views/dashboard/AMDashboard')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const MessagesPage = Loadable(lazy(() => import('views/messages')));
const PayrollPage = Loadable(lazy(() => import('views/payroll')));
const TeamPage = Loadable(lazy(() => import('views/team')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const ClientsPage = Loadable(lazy(() => import('views/clients')));
const ClientDetailPage = Loadable(lazy(() => import('views/clients/ClientDetail')));
const PlayArea = Loadable(lazy(() => import('views/sample-page2')));
const ComingSoon = Loadable(lazy(() => import('views/dashboard/ComingSoon')));
const ReportsPage = Loadable(lazy(() => import('views/reporting')));
const ReportsDetailPage = Loadable(lazy(() => import('views/reporting/ReportDetail')));
const NotFoundPage = Loadable(lazy(() => import('views/common/404')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/am',
    element: (
        <AMGuard>
            <MainLayout />
        </AMGuard>
    ),
    children: [
        {
            path: '/am',
            element: <DashboardAM />
        },
        {
            path: '/am/clients',
            element: <ClientsPage />
        },
        {
            path: '/am/client',
            element: <ClientDetailPage />,
            children: [{ path: ':id', element: <ClientDetailPage /> }]
        },
        {
            path: '/am/messages',
            element: <MessagesPage />
        },
        {
            path: '/am/calendar',
            element: <CalendarPage />
        },
        {
            path: '/am/settings',
            element: <UserSettings />
        },
        {
            path: '/am/reports',
            element: <ReportsPage />
        },
        {
            path: '/am/payroll',
            element: <PayrollPage props={{ pageName: 'Account Manager Payroll Page' }} />
        },
        {
            path: '/am/team',
            element: <TeamPage props={{ pageName: 'Account Manager Team Page' }} />
        },
        {
            path: '/am/report',
            element: <ReportsDetailPage />,
            children: [{ path: ':id', element: <ReportsDetailPage /> }]
        },
        {
            path: '/am/play-area',
            element: <PlayArea />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;