// Import redux related functions
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Import API functions related to clients and files
import { getReportListAPI, deleteReportAPI, saveAccountMappingAPI } from 'APIs/report';

const initialState = {
    initialized: false,
    isLoadingReport: false,
    reports: [],
    error: null,
    currentOauthClientLocationId: null
};

const getReportList = createAsyncThunk('reports/list', async ({ params }) => {
    console.log('>>>getReportList thunk called');
    const response = await getReportListAPI({ params });
    return response;
});

const deleteReport = createAsyncThunk('reports/delete', async ({ id }) => {
    const response = await deleteReportAPI({ id });
    return response;
});

const saveAccountMapping = createAsyncThunk('account-mapping/update', async ({ id, data }) => {
    const response = await saveAccountMappingAPI({ id, data });
    return response;
});

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        create(state, action) {
            state.reports.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.reports = [];
            state.error = null;
            state.isLoadingReport = false;
            state.currentOauthClientLocationId = null;
        },
        setIsLoadingReport(state, action) {
            state.isLoadingReport = action.payload;
        },
        setCurrentOauthClientLocationId(state, action) {
            state.currentOauthClientLocationId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportList.fulfilled, (state, action) => {
                state.reports = [...action.payload];
                state.initialized = true;
            })
            .addCase(deleteReport.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.reports = state.reports.filter((item) => item.id !== deletedID);
                }
                state.reports = [...action.payload];
                state.initialized = true;
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                }
            );
    }
});

const { create, logout, setIsLoadingReport, setCurrentOauthClientLocationId } = reportSlice.actions;
const initialized = (state) => state?.report?.initialized;
const reports = (state) => state?.report?.reports;
const isLoadingReport = (state) => state?.report?.isLoadingReport;
const currentOauthClientLocationId = (state) => state?.report?.currentOauthClientLocationId;

export {
    initialized,
    reports,
    getReportList,
    deleteReport,
    create,
    logout,
    setIsLoadingReport,
    isLoadingReport,
    currentOauthClientLocationId,
    setCurrentOauthClientLocationId
};
export default reportSlice.reducer;
