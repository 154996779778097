import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import UninvitedUserWelcomePage from 'views/common/UninvitedUserWelcome';

const GuestRoutes = {
    path: '/guest',
    element: <UninvitedUserWelcomePage />,
    children: []
};

export default GuestRoutes;
