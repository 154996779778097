import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import useAuth from 'hooks/useAuth';
import { ADMIN_DASHBOARD_PATH, ACCOUNT_MANAGER_DASHBOARD_PATH, ONBOARDING_HOME_PATH, CLIENT_DASHBOARD_PATH } from 'config';
import { userClientAPIData } from 'store/slices/accountSlice';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const ClientGuard = ({ children }) => {
    const { isLoggedIn, userType, onboardComplete } = useAuth();
    const navigate = useNavigate();
    console.log('AuthGuard Client: userType', userType);

    const clientData = useSelector(userClientAPIData);
    const clientOnboardingComplete =
        clientData?.onboarding_status?.account_info_stage_completed && clientData?.onboarding_status?.completed;

    const clientDataDoesNotExist =
        clientData === null || clientData === undefined || (clientData !== undefined && Object.keys(clientData).length === 0);

    const isClientUser = userType !== 'am' && userType !== 'admin';
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        }
        // } else if (userType === 'admin') {
        //     navigate(ADMIN_DASHBOARD_PATH, { replace: true });
        // } else if (userType === 'am') {
        //     navigate(ACCOUNT_MANAGER_DASHBOARD_PATH, { replace: true });
        // } else if (userType !== 'admin' && !clientOnboardingComplete) {
        //     navigate('/onboard', { replace: true });
        // }
        // Allow AMs and Admins to view client pages, but redirect non-onboarded clients back to onboarding
        // else if (isClientUser && clientDataDoesNotExist) {
        //     navigate('/guest', { replace: true });
        else if (isClientUser && !clientOnboardingComplete) {
            navigate('/onboard', { replace: true });
        }
    }, [isLoggedIn, userType]);

    return children;
};

ClientGuard.propTypes = {
    children: PropTypes.node
};

export default ClientGuard;
