/* eslint-disable */
export const AWS_API_BASE_URL = process.env.REACT_APP_AWS_API_BASE_URL;
export const ENV = process.env.REACT_APP_ENV;
export const DEBUG = process.env.REACT_APP_DEBUG && ENV === 'development';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// export const AWS_API = {
//     poolId: 'us-east-1_X5V16uBj4',
//     appClientId: '1c6m76gv0gv64r63shn46sh3v1',
//     region: 'us-east-1',
//     identityPoolId: 'us-east-1:70fd64bb-90bc-405c-84ed-ac762f631f0e',
// };
export const AWS_API = {
    poolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    appClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID
};

export const STORAGE = {
    AWS_S3: {
        // bucketName: 'fixe-data-public/clients/test',
        bucketName: 'fixe-data-public',
        bucketURI: 's3://fixe-data-public/clients/test/',
        region: 'us-east-1'
    }
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
export const BASE_PATH = '';

export const ADMIN_DASHBOARD_PATH = '/';
export const ONBOARDING_HOME_PATH = '/onboard';
export const CLIENT_DASHBOARD_PATH = '/client/reports';
export const ACCOUNT_MANAGER_DASHBOARD_PATH = '/am/clients';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme6', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export const countryList = [
    { id: 'US', name: 'United States of America', includeThe: true },
    { id: 'AF', name: 'Afghanistan' },
    { id: 'AX', name: 'Åland Islands' },
    { id: 'AL', name: 'Albania' },
    { id: 'DZ', name: 'Algeria' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AD', name: 'Andorra' },
    { id: 'AO', name: 'Angola' },
    { id: 'AI', name: 'Anguilla' },
    { id: 'AQ', name: 'Antarctica' },
    { id: 'AG', name: 'Antigua and Barbuda' },
    { id: 'AR', name: 'Argentina' },
    { id: 'AM', name: 'Armenia' },
    { id: 'AW', name: 'Aruba' },
    { id: 'AU', name: 'Australia' },
    { id: 'AT', name: 'Austria' },
    { id: 'AZ', name: 'Azerbaijan' },
    { id: 'BS', name: 'Bahamas', includeThe: true },
    { id: 'BH', name: 'Bahrain' },
    { id: 'BD', name: 'Bangladesh' },
    { id: 'BB', name: 'Barbados' },
    { id: 'BY', name: 'Belarus' },
    { id: 'BE', name: 'Belgium' },
    { id: 'BZ', name: 'Belize' },
    { id: 'BJ', name: 'Benin' },
    { id: 'BM', name: 'Bermuda' },
    { id: 'BT', name: 'Bhutan' },
    { id: 'BO', name: 'Bolivia, Plurinational State of' },
    { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { id: 'BA', name: 'Bosnia and Herzegovina' },
    { id: 'BW', name: 'Botswana' },
    { id: 'BV', name: 'Bouvet Island' },
    { id: 'BR', name: 'Brazil' },
    { id: 'IO', name: 'British Indian Ocean Territory', includeThe: true },
    { id: 'BN', name: 'Brunei Darussalam' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'BF', name: 'Burkina Faso' },
    { id: 'BI', name: 'Burundi' },
    { id: 'CV', name: 'Cabo Verde' },
    { id: 'KH', name: 'Cambodia' },
    { id: 'CM', name: 'Cameroon' },
    { id: 'CA', name: 'Canada' },
    { id: 'KY', name: 'Cayman Islands', includeThe: true },
    { id: 'CF', name: 'Central African Republic', includeThe: true },
    { id: 'TD', name: 'Chad' },
    { id: 'CL', name: 'Chile' },
    { id: 'CN', name: 'China' },
    { id: 'CX', name: 'Christmas Island' },
    { id: 'CC', name: 'Cocos (Keeling) Islands', includeThe: true },
    { id: 'CO', name: 'Colombia' },
    { id: 'KM', name: 'Comoros' },
    { id: 'CD', name: 'Congo, Democratic Republic of the' },
    { id: 'CG', name: 'Congo' },
    { id: 'CK', name: 'Cook Islands', includeThe: true },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'CI', name: "Côte d'Ivoire" },
    { id: 'HR', name: 'Croatia' },
    { id: 'CU', name: 'Cuba' },
    { id: 'CW', name: 'Curaçao' },
    { id: 'CY', name: 'Cyprus' },
    { id: 'CZ', name: 'Czechia' },
    { id: 'DK', name: 'Denmark' },
    { id: 'DJ', name: 'Djibouti' },
    { id: 'DM', name: 'Dominica' },
    { id: 'DO', name: 'Dominican Republic', includeThe: true },
    { id: 'EC', name: 'Ecuador' },
    { id: 'EG', name: 'Egypt' },
    { id: 'SV', name: 'El Salvador' },
    { id: 'GQ', name: 'Equatorial Guinea' },
    { id: 'ER', name: 'Eritrea' },
    { id: 'EE', name: 'Estonia' },
    { id: 'SZ', name: 'Eswatini' },
    { id: 'ET', name: 'Ethiopia' },
    { id: 'FK', name: 'Falkland Islands (Malvinas)', includeThe: true },
    { id: 'FO', name: 'Faroe Islands', includeThe: true },
    { id: 'FJ', name: 'Fiji' },
    { id: 'FI', name: 'Finland' },
    { id: 'FR', name: 'France' },
    { id: 'GF', name: 'French Guiana' },
    { id: 'PF', name: 'French Polynesia' },
    { id: 'TF', name: 'French Southern Territories' },
    { id: 'GA', name: 'Gabon' },
    { id: 'GM', name: 'Gambia', includeThe: true },
    { id: 'GE', name: 'Georgia' },
    { id: 'DE', name: 'Germany' },
    { id: 'GH', name: 'Ghana' },
    { id: 'GI', name: 'Gibraltar' },
    { id: 'GR', name: 'Greece' },
    { id: 'GL', name: 'Greenland' },
    { id: 'GD', name: 'Grenada' },
    { id: 'GP', name: 'Guadeloupe' },
    { id: 'GU', name: 'Guam' },
    { id: 'GT', name: 'Guatemala' },
    { id: 'GG', name: 'Guernsey' },
    { id: 'GW', name: 'Guinea-Bissau' },
    { id: 'GN', name: 'Guinea' },
    { id: 'GY', name: 'Guyana' },
    { id: 'HT', name: 'Haiti' },
    { id: 'HM', name: 'Heard Island and McDonald Islands' },
    { id: 'VA', name: 'Holy See' },
    { id: 'HN', name: 'Honduras' },
    { id: 'HK', name: 'Hong Kong' },
    { id: 'HU', name: 'Hungary' },
    { id: 'IS', name: 'Iceland' },
    { id: 'IN', name: 'India' },
    { id: 'ID', name: 'Indonesia' },
    { id: 'IR', name: 'Iran, Islamic Republic of' },
    { id: 'IQ', name: 'Iraq' },
    { id: 'IE', name: 'Ireland' },
    { id: 'IM', name: 'Isle of Man', includeThe: true },
    { id: 'IL', name: 'Israel' },
    { id: 'IT', name: 'Italy' },
    { id: 'JM', name: 'Jamaica' },
    { id: 'JP', name: 'Japan' },
    { id: 'JE', name: 'Jersey' },
    { id: 'JO', name: 'Jordan' },
    { id: 'KZ', name: 'Kazakhstan' },
    { id: 'KE', name: 'Kenya' },
    { id: 'KI', name: 'Kiribati' },
    { id: 'KP', name: "Korea, Democratic People's Republic of" },
    { id: 'KR', name: 'Korea, Republic of' },
    { id: 'KW', name: 'Kuwait' },
    { id: 'KG', name: 'Kyrgyzstan' },
    { id: 'LA', name: "Lao People's Democratic Republic" },
    { id: 'LV', name: 'Latvia' },
    { id: 'LB', name: 'Lebanon' },
    { id: 'LS', name: 'Lesotho' },
    { id: 'LR', name: 'Liberia' },
    { id: 'LY', name: 'Libya' },
    { id: 'LI', name: 'Liechtenstein' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LU', name: 'Luxembourg' },
    { id: 'MO', name: 'Macao' },
    { id: 'MG', name: 'Madagascar' },
    { id: 'MW', name: 'Malawi' },
    { id: 'MY', name: 'Malaysia' },
    { id: 'MV', name: 'Maldives', includeThe: true },
    { id: 'ML', name: 'Mali' },
    { id: 'MT', name: 'Malta' },
    { id: 'MH', name: 'Marshall Islands', includeThe: true },
    { id: 'MQ', name: 'Martinique' },
    { id: 'MR', name: 'Mauritania' },
    { id: 'MU', name: 'Mauritius' },
    { id: 'YT', name: 'Mayotte' },
    { id: 'MX', name: 'Mexico' },
    { id: 'FM', name: 'Micronesia, Federated States of' },
    { id: 'MD', name: 'Moldova, Republic of' },
    { id: 'MC', name: 'Monaco' },
    { id: 'MN', name: 'Mongolia' },
    { id: 'ME', name: 'Montenegro' },
    { id: 'MS', name: 'Montserrat' },
    { id: 'MA', name: 'Morocco' },
    { id: 'MZ', name: 'Mozambique' },
    { id: 'MM', name: 'Myanmar' },
    { id: 'NA', name: 'Namibia' },
    { id: 'NR', name: 'Nauru' },
    { id: 'NP', name: 'Nepal' },
    { id: 'NL', name: 'Netherlands', includeThe: true },
    { id: 'NC', name: 'New Caledonia' },
    { id: 'NZ', name: 'New Zealand' },
    { id: 'NI', name: 'Nicaragua' },
    { id: 'NE', name: 'Niger' },
    { id: 'NG', name: 'Nigeria' },
    { id: 'NU', name: 'Niue' },
    { id: 'NF', name: 'Norfolk Island' },
    { id: 'MK', name: 'North Macedonia' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'NO', name: 'Norway' },
    { id: 'OM', name: 'Oman' },
    { id: 'PK', name: 'Pakistan' },
    { id: 'PW', name: 'Palau' },
    { id: 'PS', name: 'Palestine, State of' },
    { id: 'PA', name: 'Panama' },
    { id: 'PG', name: 'Papua New Guinea' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'PE', name: 'Peru' },
    { id: 'PH', name: 'Philippines', includeThe: true },
    { id: 'PN', name: 'Pitcairn' },
    { id: 'PL', name: 'Poland' },
    { id: 'PT', name: 'Portugal' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'QA', name: 'Qatar' },
    { id: 'RE', name: 'Réunion' },
    { id: 'RO', name: 'Romania' },
    { id: 'RU', name: 'Russian Federation', includeThe: true },
    { id: 'RW', name: 'Rwanda' },
    { id: 'BL', name: 'Saint Barthélemy' },
    { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { id: 'KN', name: 'Saint Kitts and Nevis' },
    { id: 'LC', name: 'Saint Lucia' },
    { id: 'MF', name: 'Saint Martin, (French part)' },
    { id: 'PM', name: 'Saint Pierre and Miquelon' },
    { id: 'VC', name: 'Saint Vincent and the Grenadines' },
    { id: 'WS', name: 'Samoa' },
    { id: 'SM', name: 'San Marino' },
    { id: 'ST', name: 'Sao Tome and Principe' },
    { id: 'SA', name: 'Saudi Arabia' },
    { id: 'SN', name: 'Senegal' },
    { id: 'RS', name: 'Serbia' },
    { id: 'SC', name: 'Seychelles', includeThe: true },
    { id: 'SL', name: 'Sierra Leone' },
    { id: 'SG', name: 'Singapore' },
    { id: 'SX', name: 'Sint Maarten, (Dutch part)' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SB', name: 'Solomon Islands', includeThe: true },
    { id: 'SO', name: 'Somalia' },
    { id: 'ZA', name: 'South Africa' },
    { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'SS', name: 'South Sudan' },
    { id: 'ES', name: 'Spain' },
    { id: 'LK', name: 'Sri Lanka' },
    { id: 'SD', name: 'Sudan' },
    { id: 'SR', name: 'Suriname' },
    { id: 'SJ', name: 'Svalbard and Jan Mayen' },
    { id: 'SE', name: 'Sweden' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'SY', name: 'Syrian Arab Republic', includeThe: true },
    { id: 'TW', name: 'Taiwan, Province of China' },
    { id: 'TJ', name: 'Tajikistan' },
    { id: 'TZ', name: 'Tanzania, United Republic of' },
    { id: 'TH', name: 'Thailand' },
    { id: 'TL', name: 'Timor-Leste' },
    { id: 'TG', name: 'Togo' },
    { id: 'TK', name: 'Tokelau' },
    { id: 'TO', name: 'Tonga' },
    { id: 'TT', name: 'Trinidad and Tobago' },
    { id: 'TN', name: 'Tunisia' },
    { id: 'TR', name: 'Turkey' },
    { id: 'TM', name: 'Turkmenistan' },
    { id: 'TC', name: 'Turks and Caicos Islands', includeThe: true },
    { id: 'TV', name: 'Tuvalu' },
    { id: 'UG', name: 'Uganda' },
    { id: 'UA', name: 'Ukraine' },
    { id: 'AE', name: 'United Arab Emirates', includeThe: true },
    { id: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland', includeThe: true },
    { id: 'UM', name: 'United States Minor Outlying Islands', includeThe: true },
    { id: 'UY', name: 'Uruguay' },
    { id: 'UZ', name: 'Uzbekistan' },
    { id: 'VU', name: 'Vanuatu' },
    { id: 'VE', name: 'Venezuela, Bolivarian Republic of' },
    { id: 'VN', name: 'Viet Nam' },
    { id: 'VG', name: 'British Virgin Islands', includeThe: true },
    { id: 'VI', name: 'U.S. Virgin Islands', includeThe: true },
    { id: 'WF', name: 'Wallis and Futuna' },
    { id: 'EH', name: 'Western Sahara', includeThe: true },
    { id: 'YE', name: 'Yemen' },
    { id: 'ZM', name: 'Zambia' },
    { id: 'ZW', name: 'Zimbabwe' }
];

export const stateList = [
    { id: 'AL', name: 'Alabama' },
    { id: 'AK', name: 'Alaska' },
    { id: 'AZ', name: 'Arizona' },
    { id: 'AR', name: 'Arkansas' },
    { id: 'CA', name: 'California' },
    { id: 'CO', name: 'Colorado' },
    { id: 'CT', name: 'Connecticut' },
    { id: 'DE', name: 'Delaware' },
    { id: 'FL', name: 'Florida' },
    { id: 'GA', name: 'Georgia' },
    { id: 'HI', name: 'Hawaii' },
    { id: 'ID', name: 'Idaho' },
    { id: 'IL', name: 'Illinois' },
    { id: 'IN', name: 'Indiana' },
    { id: 'IA', name: 'Iowa' },
    { id: 'KS', name: 'Kansas' },
    { id: 'KY', name: 'Kentucky' },
    { id: 'LA', name: 'Louisiana' },
    { id: 'ME', name: 'Maine' },
    { id: 'MD', name: 'Maryland' },
    { id: 'MA', name: 'Massachusetts' },
    { id: 'MI', name: 'Michigan' },
    { id: 'MN', name: 'Minnesota' },
    { id: 'MS', name: 'Mississippi' },
    { id: 'MO', name: 'Missouri' },
    { id: 'MT', name: 'Montana' },
    { id: 'NE', name: 'Nebraska' },
    { id: 'NV', name: 'Nevada' },
    { id: 'NH', name: 'New Hampshire' },
    { id: 'NJ', name: 'New Jersey' },
    { id: 'NM', name: 'New Mexico' },
    { id: 'NY', name: 'New York' },
    { id: 'NC', name: 'North Carolina' },
    { id: 'ND', name: 'North Dakota' },
    { id: 'OH', name: 'Ohio' },
    { id: 'OK', name: 'Oklahoma' },
    { id: 'OR', name: 'Oregon' },
    { id: 'PA', name: 'Pennsylvania' },
    { id: 'RI', name: 'Rhode Island' },
    { id: 'SC', name: 'South Carolina' },
    { id: 'SD', name: 'South Dakota' },
    { id: 'TN', name: 'Tennessee' },
    { id: 'TX', name: 'Texas' },
    { id: 'UT', name: 'Utah' },
    { id: 'VT', name: 'Vermont' },
    { id: 'VA', name: 'Virginia' },
    { id: 'WA', name: 'Washington' },
    { id: 'WV', name: 'West Virginia' },
    { id: 'WI', name: 'Wisconsin' },
    { id: 'WY', name: 'Wyoming' }
];
export const USA_ID = 'US';

export const taskPriorities = [
    { id: '0', name: 'Low', color: 'information' },
    { id: '1', name: 'Medium', color: 'success' },
    { id: '2', name: 'High', color: 'error' }
];

export const messagePriorities = [
    { id: '0', name: 'Low', color: 'information' },
    { id: '1', name: 'Medium', color: 'success' },
    { id: '2', name: 'High', color: 'error' }
];

export const userTypes = [
    { id: 'admin', name: 'Administrator' },
    { id: 'am', name: 'Account Manager' },
    { id: 'client', name: 'Client' }
];

export const FIXE_SUPPORT_EMAIL_DOMAIN = 'support.getmyfixe.com';

export const POS_SYSTEM_NAMES = [
    { value: 'toast', text: 'Toast' },
    { value: 'square', text: 'Square' },
    { value: 'revel', text: 'Revel' },
    { value: 'micros', text: 'Micros' },
    { value: 'clover', text: 'Clover' },
    { value: 'other', text: 'Other' }
];

export const thirdPartySystems = [
    'Doordash',
    'Grubhub',
    'UberEats'
    // "Caviar",
    // "Tok",
    // "Chow Now",
    // "Bento Box",
    // "Ritual",
    // "Otter",
    // "EZ Cater",
    // "Olo"
];

export const onboardingStages = ['Account Info', 'Operations'];
export const onboardingAccountInfoSteps = ['Location', 'Plan', 'Billing', 'Agreement', 'Fixe Email'];

export const plans = [
    {
        value: '1',
        label: '1 - Core'
    },
    {
        value: '2',
        label: '2 - Pro'
    },
    {
        value: '3',
        label: '3 - Premium'
    },
    {
        value: '4',
        label: '4 - Management'
    },
    {
        value: '5',
        label: '5 - Special'
    }
];

export const clientCommunicationLevels = [
    {
        value: '1',
        label: 'Minimal/Almost None (0-12 emails a month)'
    },
    {
        value: '2',
        label: 'Intermediate (13-24 emails a month)'
    },
    {
        value: '3',
        label: 'Moderate (25-60 emails a month)'
    },
    {
        value: '4',
        label: 'Maximum (60 + emails a month)'
    }
];

export const clientCommunicationStyles = [
    {
        value: '1',
        label: 'Email only'
    },
    {
        value: '2',
        label: 'Email and some texts/calls (for emergencies only)'
    },
    {
        value: '3',
        label: 'Not an emailer - only able to text or call most of the time'
    },
    {
        value: '4',
        label: 'Emails, texts, and calls often (lots of emergencies)'
    }
];

export const clientDetailExpectedOptions = [
    {
        value: '1',
        label: 'Minimal - We make most decisions'
    },
    {
        value: '2',
        label: 'Moderate - Some client involvement'
    },
    {
        value: '3',
        label: 'Maximum - High client scrutiny'
    }
];

export const clientPersonalityTypes = [
    {
        value: '1',
        label: 'Mild - laid back and easygoing, very friendly'
    },
    {
        value: '2',
        label: 'Moderate - more analytical, high but realistic expectations'
    },
    {
        value: '3',
        label: 'Intense - very analytical, extremely high expectations, less predictable temperament'
    }
];

export const payrollSystems = [
    { value: 'adp-run', label: 'ADP Run' },
    { value: 'gusto', label: 'Gusto' },
    { value: 'square', label: 'Square' },
    { value: 'paychex', label: 'Paychex' },
    { value: 'toast', label: 'Toast' }
];

export const paySchedules = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'bi-weekly', label: 'Bi-Weekly' },
    { value: 'semi-monthly', label: 'Semi-Monthly' },
    { value: 'monthly', label: 'Monthly' }
];

// Maps the reportType values returned from the API to more
// human-readable, formatted labels for displaying to users
export const reportTypeMapping = {
    pandl: 'P&L',
    flash: 'Flash',
    apaging: 'A/P Aging',
    balance: 'Balance'
};

export const accountManagersList = [
    'Ana Martinez',
    'Bart McPhail',
    'Billy Joe Martin',
    'Carolyn Esteban',
    'Celeste Carpio',
    'Dawnielle Huffman',
    'Emily Ngo',
    'Ezra Wickwire-George',
    'Jada Jones',
    'Jedidia Esteban',
    'Justine Buenaventura',
    'Kevin Tizon',
    'Kris Kay',
    'Leneth Musni',
    'Maria Carpio',
    'Mariana Maritinez',
    'Micky Esteban',
    'Nicoll Maligaya',
    'Olivia Buley',
    'Rayniel Esteban',
    'Rein Palo',
    'Rina Geroy',
    'Sam Tremblay',
    'Sandra Harris',
    'Terese Villiere',
    'Thikha Ngo',
    'Tiffany Baker',
    'Veronica Delgado',
    'Zyrene Esteban'
].sort();

// export {
//     AWS_API_BASE_URL,
//     ENV,
//     API_BASE_URL,
//     API_SERVER_URL,
//     GOOGLE_MAPS_API_KEY,
//     AWS_API,
//     STORAGE,
//     BASE_PATH,
//     ADMIN_DASHBOARD_PATH,
//     ONBOARDING_HOME_PATH,
//     CLIENT_DASHBOARD_PATH,
//     ACCOUNT_MANAGER_DASHBOARD_PATH,
//     countryList,
//     stateList,
//     USA_ID,
//     taskPriorities,
//     messagePriorities,
//     userTypes,
//     FIXE_SUPPORT_EMAIL_DOMAIN,
//     POS_SYSTEM_NAMES,
//     thirdPartySystems,
//     onboardingStages,
//     onboardingAccountInfoSteps,
//     plans,
//     clientCommunicationLevels,
//     clientCommunicationStyles,
//     clientDetailExpectedOptions,
//     clientPersonalityTypes,
//     payrollSystems,
//     paySchedules,
//     reportTypeMapping
// }

export default config;