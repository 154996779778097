import { useRoutes } from 'react-router-dom';

// routes
import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';
import ClientRoutes from './ClientRoutes';
import AMRoutes from './AMRoutes';
import OnboardingRoutes from './OnboardingRoutes';
import GuestRoutes from './GuestRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AdminRoutes, AMRoutes, OnboardingRoutes, ClientRoutes, LoginRoutes, GuestRoutes]);
}
