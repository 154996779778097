import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMessageListAPI, updateMessageAPI, createMessageAPI, deleteMessageAPI } from 'APIs/message';

// ==============================|| REDUX STORE: EVENT DATA ||============================== //

const getMessageList = createAsyncThunk('message/list', async () => {
    const response = await getMessageListAPI();
    return response;
});

const createMessage = createAsyncThunk('message/create', async ({ data }) => {
    const response = await createMessageAPI({ data });
    return response;
});

const updateMessage = createAsyncThunk('message/update', async ({ id, data }) => {
    const response = await updateMessageAPI({ id, data });
    return response;
});

const deleteMessage = createAsyncThunk('message/delete', async ({ id }) => {
    const response = await deleteMessageAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    messages: []
};

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        create(state, action) {
            state.messages.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.messages = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMessage.fulfilled, (state, action) => {
                state.messages.push(action.payload);
            })
            .addCase(updateMessage.fulfilled, (state, action) => {
                state.messages = state.messages.map((message) => {
                    if (message.id === action.payload.id) {
                        return { ...message, ...action.payload };
                    }
                    return message;
                });
            })
            .addCase(deleteMessage.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.messages = state.messages.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(getMessageList.fulfilled, (state, action) => {
                state.messages = [...action.payload];
                state.initialized = true;
            })
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/1/fulfilled'),
                (state, action) => {
                    // Nev Message
                    console.log('action-message', action.payload.message);
                    state.messages.push(action.payload.message);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/2/fulfilled'),
                (state, action) => {
                    // Nev Message
                    state.messages.push(action.payload.message);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cndEmail/fulfilled'),
                (state, action) => {
                    // Nev Message
                    state.messages.push(action.payload.message);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cnd2Email/fulfilled'),
                (state, action) => {
                    // Nev Message
                    state.messages.push(action.payload.message);
                }
            );
    }
});

const { logout } = messageSlice.actions;
const initialized = (state) => state?.message?.initialized;
const messages = (state) => state?.message?.messages;

export { getMessageList, updateMessage, initialized, messages, createMessage, deleteMessage, logout };
export default messageSlice.reducer;
