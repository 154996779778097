// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconCoin, IconDashboard, IconMessages, IconChartBar, IconCash, IconUsers } from '@tabler/icons';
import { Typography } from '@mui/material';

// ===========================|| CLIENT MENU OPTIONS ||=========================== //

const client = {
    id: 'client-menu',
    // title: <FormattedMessage id="dashboard" />,
    title: <FormattedMessage id="client-dashboard-title" />,
    type: 'group',
    children: [
        {
            id: 'menu-dashboard',
            title: <FormattedMessage id="menu-dashboard" />,
            type: 'item',
            url: '/client',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'menu-messages',
            title: <FormattedMessage id="menu-messages" />,
            type: 'item',
            url: '/client/messages',
            icon: IconMessages,
            breadcrumbs: false
        },
        {
            id: 'menu-reports',
            title: <FormattedMessage id="menu-reports" />,
            type: 'item',
            url: '/client/reports',
            icon: IconChartBar,
            breadcrumbs: false
        },
        {
            id: 'menu-payroll',
            title: <FormattedMessage id="menu-payroll" />,
            type: 'item',
            url: '/client/payroll',
            icon: IconCash,
            breadcrumbs: false
        },
        {
            id: 'menu-team',
            title: <FormattedMessage id="menu-team" />,
            type: 'item',
            url: '/client/team',
            icon: IconUsers,
            breadcrumbs: false
        }
        // {
        //     id: 'play-area',
        //     title: 'Play Area',
        //     type: 'item',
        //     url: '/client/play',
        //     icon: IconMessages,
        //     breadcrumbs: false
        // }
    ]
};

export default client;
